import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "shop-card__content-container" }
const _hoisted_3 = { class: "shop-card__image-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]

import { computed } from 'vue';
import { getBaseUrl } from '@utils/environmentUtils';
import { ShopCard } from '@/interfaces/shop-info';
import RoundedButton from '../RoundedButton/RoundedButton.vue';
import ShopContactData from '../ShopContactData/ShopContactData.vue';

interface Props {
	shopList: ShopCard;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopCard',
  props: {
    shopList: {}
  },
  setup(__props: any) {

const props = __props;

const imgPath = computed((): string => (props.shopList.image ? getBaseUrl() + props.shopList.image : ''));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "shop-card",
    href: _ctx.shopList.uri_detail
  }, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.shopList.name), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (imgPath.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: imgPath.value,
              class: "shop-card__image"
            }, null, 8 /* PROPS */, _hoisted_4))
          : (_openBlock(), _createElementBlock("div", _hoisted_5))
      ]),
      _createVNode(ShopContactData, {
        "shop-info": _ctx.shopList,
        class: "shop-card__contact-data"
      }, null, 8 /* PROPS */, ["shop-info"]),
      _createElementVNode("div", {
        class: "shop-card__further-info",
        innerHTML: _ctx.shopList.introtext
      }, null, 8 /* PROPS */, _hoisted_6)
    ]),
    _createVNode(RoundedButton, {
      theme: "extra",
      class: "shop-card__button",
      title: _ctx.shopList.name
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Reisebüroinfo ")
      ])),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ], 8 /* PROPS */, _hoisted_1))
}
}

})