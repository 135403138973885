import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "shop-list" }
const _hoisted_2 = {
  key: 0,
  class: "shop-list__links-container"
}
const _hoisted_3 = ["id", "aria-current"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "shop-list__card-container" }

import { onBeforeMount, onMounted, ref } from 'vue';
import { ShopInfo as ShopList } from '@/interfaces/shop-info';
import { scrollToAnchor } from '@/js/utils/utils';
import ShopCard from '../ShopCard/ShopCard.vue';

interface Props {
	shopList: ShopList[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopList',
  props: {
    shopList: {}
  },
  setup(__props: any) {

const props = __props;

const currentLocation = document.location.protocol + '//' + document.location.host + document.location.pathname;

const menu = ref<HTMLElement |null>(null);

const sections = ref<HTMLElement[] |null>(null);

const sectionsMap:{
	name: string,
	nameOriginal: string,
	city: string | undefined,
	element: HTMLElement | undefined,
	inViewport: boolean,
	numberOfShopsInCity: number,
	link: string}[] = [];

const firstVisibleSectionName = ref('Wien');

const scrollToMenuEntry = (stateEntry: string) => {
	const htmlElementEntry = document.querySelector(`#${stateEntry}-menu-entry`);
	if (menu.value && htmlElementEntry) {
		const currentMenuScroll = menu.value.scrollLeft as number;
		const menuPosition = menu.value.getBoundingClientRect().left as number;
		menu.value.scrollTo({
			top: 0,
			left: htmlElementEntry.getBoundingClientRect().left + currentMenuScroll - menuPosition,
			behavior: 'smooth'
		});
	}
};

const updateActiveMenu = () => {
	for (let i = 0; i < sectionsMap.length; i++) {
		sectionsMap[i].element = sections.value?.[i];
	}

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((e) => {
			sectionsMap.forEach((o) => {
				if (e.target === o.element) {
					// eslint-disable-next-line no-param-reassign
					o.inViewport = e.isIntersecting;
				}
			});
			const firstVisibleSection = sectionsMap.find((s) => s.inViewport);
			if (firstVisibleSection) {
				firstVisibleSectionName.value = firstVisibleSection.name;
				scrollToMenuEntry(firstVisibleSection.name);
			}
		});
	}, {
		rootMargin: '-250px 0px 0px 0px',
	});

	sectionsMap.forEach((o) => {
		const section = document.querySelector('#' + o.name);
		if (section) {
			observer.observe(section);
		}
	});
};

const initMenu = () => {
	const statesSet = Array.from(props.shopList.filter((entry, index) => props.shopList.map((s) => s.state).indexOf(entry.state) === index));
	statesSet.forEach((s) => {
		const shops = props.shopList.filter((shopinfo) => shopinfo.city === s.city).length;
		sectionsMap.push({
			name: s.state_link,
			nameOriginal: s.state,
			city: s.city,
			inViewport: false,
			element: undefined,
			link: s.shop_list_link,
			numberOfShopsInCity: shops
		});
	});
};

onMounted(() => {
	updateActiveMenu();
});

onBeforeMount(() => {
	initMenu();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (sectionsMap.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", {
            ref_key: "menu",
            ref: menu,
            class: "shop-list__links"
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sectionsMap, (s) => {
              return _createElementVNode("li", {
                id: s.name + '-menu-entry',
                key: s.name,
                "aria-current": firstVisibleSectionName.value === s.name
              }, [
                _createElementVNode("a", {
                  href: currentLocation + '#' + s.name,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(scrollToAnchor)($event, 100)), ["prevent"]))
                }, _toDisplayString(s.nameOriginal), 9 /* TEXT, PROPS */, _hoisted_4)
              ], 8 /* PROPS */, _hoisted_3)
            }), 64 /* STABLE_FRAGMENT */))
          ], 512 /* NEED_PATCH */)
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sectionsMap, (s) => {
      return _createElementVNode("div", {
        id: s.name,
        key: s.name,
        ref_for: true,
        ref_key: "sections",
        ref: sections,
        class: "shop-list__state-container"
      }, [
        _createElementVNode("h2", null, _toDisplayString(s.nameOriginal), 1 /* TEXT */),
        (s.numberOfShopsInCity > 1)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_6, [
              (s.link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: s.link
                  }, "Reisebüros in " + _toDisplayString(s.city), 9 /* TEXT, PROPS */, _hoisted_7))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "Reisebüros in " + _toDisplayString(s.city), 1 /* TEXT */))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopList.filter((shopinfo) => shopinfo.state === s.nameOriginal), (item, index) => {
            return (_openBlock(), _createBlock(ShopCard, {
              key: index,
              "shop-list": item,
              class: "shop-list__card"
            }, null, 8 /* PROPS */, ["shop-list"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ], 8 /* PROPS */, _hoisted_5)
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})