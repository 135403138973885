import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quickinfos" }

import ClimateChart from '@lmt-rpb/ClimateChart/ClimateChart.vue';
import RegionFacts from '@lmt-rpb/RegionFacts/RegionFacts.vue';
import RegionMap from '@lmt-rpb/Map/RegionMap.vue';
import { StaticMaps, MarkerData } from '@/interfaces/google-maps';
import { ClimateData, DestinationFacts } from '@/interfaces/quickinfo-types';

interface Props {
	destinationData: DestinationFacts;
	climateData: ClimateData;
	staticMaps: StaticMaps;
	customMarkerData: Array<MarkerData>;
	climateRegion: string;
	northwest: {lat: number, lng: number} | null,
	southeast: {lat: number, lng: number} | null,
	lat: number;
	lng: number;
	zoom: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Quickinfos',
  props: {
    destinationData: {},
    climateData: {},
    staticMaps: {},
    customMarkerData: {},
    climateRegion: {},
    northwest: {},
    southeast: {},
    lat: {},
    lng: {},
    zoom: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(ClimateChart, {
      "climate-data": _ctx.climateData,
      "climate-region": _ctx.climateRegion
    }, null, 8 /* PROPS */, ["climate-data", "climate-region"]),
    _createVNode(RegionFacts, { "destination-data": _ctx.destinationData }, null, 8 /* PROPS */, ["destination-data"]),
    _createVNode(RegionMap, {
      "static-maps": _ctx.staticMaps,
      "destination-name": _ctx.destinationData.destinationName,
      lat: _ctx.lat,
      lng: _ctx.lng,
      northwest: _ctx.northwest,
      southeast: _ctx.southeast,
      zoom: _ctx.zoom,
      "custom-marker-data": _ctx.customMarkerData
    }, null, 8 /* PROPS */, ["static-maps", "destination-name", "lat", "lng", "northwest", "southeast", "zoom", "custom-marker-data"])
  ]))
}
}

})