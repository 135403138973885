import { createApp } from 'vue';
import QuickPillList from '@lmt-rpb/QuickPillList/QuickPillList.vue';
import type { QuickPillType } from '@lmt-rpb/QuickPillList/types';
import { getDefaultCurrency } from '@utils/environmentUtils';
import { mountReplace } from '../utils/vue-mount-replacer';

/**
 * Init function for the Quickpills component
 */
const initQuickpills = (selector: string): void => {
	const mountPoint = document.querySelector(selector) as HTMLElement;
	const headerData = mountPoint.dataset;
	const isAutoGenerated = headerData.autoGenerated;
	const enableEventToggling = !!headerData?.enableEventToggling;
	const quickPillsDataElements: NodeList | null | undefined = isAutoGenerated
		? mountPoint?.nextElementSibling?.querySelectorAll('.data-quickpill')
		: mountPoint.querySelectorAll('.data-quickpill');
	const pills: QuickPillType[] = [];
	const headerProps = {
		title: headerData?.header || '',
		subtitle: headerData?.subheader || '',
		mobileTitle: headerData?.headerMobile || '',
		mobileSubtitle: headerData?.subheaderMobile || '',
		moreText: headerData?.linkText || 'Mehr',
		link: headerData?.moreLink,
		titleAttribute: (headerData.headerMobile || headerData.header) || '',

	};

	if (quickPillsDataElements) {
		quickPillsDataElements.forEach((dataElement) => {
			const dataset = (dataElement as HTMLElement)?.dataset;
			const priceElement = (dataElement as HTMLElement).querySelector('.rpb_quickpills__price');
			const priceData = (priceElement as HTMLElement)?.dataset;

			pills.push({
				destination: dataset?.destinationName || '',
				url: dataset?.url || '',
				price: priceData?.price || dataset?.price || '',
				titleTag: dataset?.titleTag || '',
				linkTarget: dataset?.linkTarget || '',
			});
		});
	}

	const app = createApp(QuickPillList, {
		headerProps,
		pills,
		limit: headerData?.limit ? Number(headerData.limit) : undefined,
		enableEventToggling,
		currency: getDefaultCurrency(),
	});
	mountReplace(app, mountPoint);
};

const intersectionCallback = (entries: IntersectionObserverEntry[]): void => {
	if (entries[0].isIntersecting) {
		initQuickpills('.' + entries[0].target.classList[1]);
	}
};

const createQuickPillsObserver = (selector: string): void => {
	const hotelGridObserver = new IntersectionObserver(intersectionCallback, {
		root: null,
		rootMargin: '200px',
	});
	const hotelGridTarget = document.querySelector(selector);
	if (hotelGridTarget) {
		hotelGridObserver.observe(hotelGridTarget);
	}
};

(function() {
	const quickPillsMountPoints = document.querySelectorAll('.rpb_quickpills__mountpoint');

	if (!quickPillsMountPoints.length) {
		return;
	}

	// Array for multiple instances of quickpills
	const vueSelectorList: string[] = [];

	for (let i = 0; i < quickPillsMountPoints.length; i++) {
		const vueSelector = 'rpb_quickpills__mountpoint--' + i;
		quickPillsMountPoints[i].classList.add(vueSelector);
		vueSelectorList[i] = '.' + vueSelector;
	}

	vueSelectorList.forEach((selector) => {
		const isAutoGenerated = (document.querySelector(selector) as HTMLElement)?.dataset.autoGenerated;

		if (isAutoGenerated) {
			initQuickpills(selector);
		} else {
			createQuickPillsObserver(selector);
		}
	});
}());
