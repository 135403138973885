import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shop-details" }
const _hoisted_2 = { class: "shop-details__wrapper" }
const _hoisted_3 = { class: "shop-details__first-section" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "shop-details__employees-wrapper" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "shop-details__employee-info" }
const _hoisted_10 = { class: "shop-details__employee-name" }
const _hoisted_11 = { class: "shop-details__employee-role" }
const _hoisted_12 = {
  key: 0,
  class: "shop-details__employee-languages"
}
const _hoisted_13 = {
  key: 1,
  class: "shop-details__employee-phone"
}
const _hoisted_14 = ["href"]
const _hoisted_15 = {
  key: 2,
  class: "shop-details__employee-email"
}
const _hoisted_16 = ["href"]

import { ref } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ShopDetails } from '@/interfaces/shop-info';
import ShopContactData from '../ShopContactData/ShopContactData.vue';

interface Props {
	shopInfo: ShopDetails;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopDetails',
  props: {
    shopInfo: {}
  },
  setup(__props: any) {


const props = __props;

const mapLink = ref<HTMLAnchorElement>();

const consentGiven = ref(false);

const handleConsent = (): void => {
	if (window.UC_UI) {
		window.UC_UI.getServicesFullInfo().then((services: { name: string; consent: { status: boolean; }; }[]) => {
			services.forEach((serviceObject) => {
				if (serviceObject.name === 'Google Maps' && serviceObject.consent.status && !consentGiven.value) {
					consentGiven.value = true;
					setTimeout(() => {
						if (mapLink.value) {
							mapLink.value.href = props.shopInfo.url;
						}
					}, 200);
				}
			});
		});
	}
};

window.addEventListener('UC_UI_INITIALIZED', handleConsent);
window.addEventListener('UC_UI_CMP_EVENT', handleConsent);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.shopInfo.name), 1 /* TEXT */),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(ShopContactData, {
          "shop-info": _ctx.shopInfo,
          class: "shop-details__contact-data"
        }, null, 8 /* PROPS */, ["shop-info"]),
        _createElementVNode("a", {
          ref_key: "mapLink",
          ref: mapLink,
          href: undefined,
          target: "_blank",
          class: _normalizeClass(["shop-details__map-container", {'shop-details__map-container--allowed': consentGiven.value}])
        }, [
          _createElementVNode("img", {
            src: _ctx.shopInfo.staticgmap_mobile,
            alt: `Google Map von ${_ctx.shopInfo.name}`,
            class: "shop-details__map"
          }, null, 8 /* PROPS */, _hoisted_4)
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("section", {
        class: "shop-details__further-info",
        innerHTML: _ctx.shopInfo.introtext
      }, null, 8 /* PROPS */, _hoisted_5),
      _createElementVNode("h2", null, " Das Team des Reisebüros " + _toDisplayString(_ctx.shopInfo.name), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopInfo.staff, (e, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "shop-details__employee"
          }, [
            (e.image)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "shop-details__employee-img",
                  width: "480",
                  height: "554",
                  src: e.image,
                  alt: `${e.first_name} ${e.last_name}`
                }, null, 8 /* PROPS */, _hoisted_7))
              : (_openBlock(), _createElementBlock("div", _hoisted_8)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(e.first_name) + " " + _toDisplayString(e.last_name), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_11, _toDisplayString(e.title), 1 /* TEXT */),
              (e.languages)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Sprachen: " + _toDisplayString(e.languages), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (e.telephone_link)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(BaseIcon, {
                      name: "phone",
                      class: "shop-card-contact-data__phone",
                      width: "20",
                      height: "20"
                    }),
                    _createElementVNode("a", {
                      href: `tel:${e.telephone_link}`
                    }, _toDisplayString(e.telephone), 9 /* TEXT, PROPS */, _hoisted_14)
                  ]))
                : _createCommentVNode("v-if", true),
              (e.email)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(BaseIcon, {
                      name: "envelope",
                      class: "shop-card-contact-data__envelope",
                      width: "20",
                      height: "20"
                    }),
                    _createElementVNode("a", {
                      href: `mailto:${e.email}`,
                      class: "shop-card-contact-data__email"
                    }, "E-Mail schreiben", 8 /* PROPS */, _hoisted_16)
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}
}

})