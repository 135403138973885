import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quickinfos__region-info region-facts" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

import { DestinationFacts } from '@/interfaces/quickinfo-types';


export default /*@__PURE__*/_defineComponent({
  __name: 'RegionFacts',
  props: {
    destinationData: {}
  },
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "region-facts__headline" }, " Kurzinfos ", -1 /* HOISTED */)),
      _createElementVNode("div", {
        class: "region-facts__description",
        innerHTML: _ctx.destinationData.facts
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", {
        class: "region-facts__intro-text",
        innerHTML: _ctx.destinationData.introtext
      }, null, 8 /* PROPS */, _hoisted_4),
      _createElementVNode("div", {
        class: "region-facts__best-time",
        innerHTML: _ctx.destinationData.bestTimeToVisit
      }, null, 8 /* PROPS */, _hoisted_5)
    ])
  ]))
}
}

})