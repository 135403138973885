import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "shop-card-contact-data" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = { class: "shop-card-contact-data__open-hours" }
const _hoisted_6 = ["innerHTML"]

import { ShopInfo } from '@/interfaces/shop-info';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

interface Props {
	shopInfo: ShopInfo;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopContactData',
  props: {
    shopInfo: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(BaseIcon, {
        name: "marker",
        class: "shop-card-contact-data__marker",
        height: "20"
      }),
      _createElementVNode("a", {
        href: _ctx.shopInfo.url,
        target: "_blank",
        class: "shop-card-contact-data__address"
      }, _toDisplayString(_ctx.shopInfo.zip) + " " + _toDisplayString(_ctx.shopInfo.city) + ", " + _toDisplayString(_ctx.shopInfo.address), 9 /* TEXT, PROPS */, _hoisted_2)
    ]),
    _createElementVNode("div", null, [
      _createVNode(BaseIcon, {
        name: "phone",
        class: "shop-card-contact-data__phone",
        width: "20",
        height: "20"
      }),
      _createElementVNode("a", {
        href: 'tel:' + _ctx.shopInfo.phone_link
      }, _toDisplayString(_ctx.shopInfo.phone), 9 /* TEXT, PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", null, [
      _createVNode(BaseIcon, {
        name: "envelope",
        class: "shop-card-contact-data__envelope",
        width: "20",
        height: "20"
      }),
      _createElementVNode("a", {
        href: 'mailto:' + _ctx.shopInfo.email,
        title: 'mailto:' + _ctx.shopInfo.email,
        class: "shop-card-contact-data__email"
      }, "E-Mail schreiben", 8 /* PROPS */, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", {
        innerHTML:  _ctx.shopInfo.open_hours
      }, null, 8 /* PROPS */, _hoisted_6)
    ])
  ]))
}
}

})