import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "climate-chart" }
const _hoisted_2 = { class: "climate-chart__headline" }
const _hoisted_3 = { class: "climate-chart__infos" }
const _hoisted_4 = { class: "climate-chart__air-temperature" }
const _hoisted_5 = { class: "rpb_air-temperature-minmax--icon" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "climate-chart__climate-stats" }
const _hoisted_9 = { class: "climate-chart__label" }
const _hoisted_10 = { class: "climate-chart__label-value" }
const _hoisted_11 = { class: "climate-chart__label" }
const _hoisted_12 = { class: "climate-chart__label-value" }
const _hoisted_13 = { class: "rpb_destination__climate-graph" }
const _hoisted_14 = { class: "climate-chart__track" }
const _hoisted_15 = ["index", "onMouseenter"]
const _hoisted_16 = { class: "climate-chart__initial" }

import { ref, computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ClimateData, MonthClimate } from '@/interfaces/quickinfo-types';

interface Props {
	climateRegion: string,
	climateData: ClimateData
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ClimateChart',
  props: {
    climateRegion: {},
    climateData: {}
  },
  setup(__props: any) {

const props = __props;

const yAxisStart = ref(0);
const yAxisEnd = ref(40);

const calculateHeight = (month: MonthClimate): string => {
	const average = (month.max + month.min) / 2;
	const num = ((average - yAxisStart.value) / (yAxisEnd.value - yAxisStart.value)) * 100;
	return `height: ${num}%`;
};

const minTemp = props.climateData?.min_temperature;

const maxTemp = props.climateData?.max_temperature;

const sunshineHours = props.climateData?.sunshine_hours;

const waterTemp = props.climateData?.water_temperature;

const months = [
	{
		initial: 'J',
		max: maxTemp[1],
		min: minTemp[1],
		sunshineHours: sunshineHours[1],
		waterTemp: waterTemp[1],
		ID: 1,
	},
	{
		initial: 'F',
		max: maxTemp[2],
		min: minTemp[2],
		sunshineHours: sunshineHours[2],
		waterTemp: waterTemp[2],
		ID: 2,
	},
	{
		initial: 'M',
		max: maxTemp[3],
		min: minTemp[3],
		sunshineHours: sunshineHours[3],
		waterTemp: waterTemp[3],
		ID: 3,
	},
	{
		initial: 'A',
		max: maxTemp[4],
		min: minTemp[4],
		sunshineHours: sunshineHours[4],
		waterTemp: waterTemp[4],
		ID: 4,
	},
	{
		initial: 'M',
		max: maxTemp[5],
		min: minTemp[5],
		sunshineHours: sunshineHours[5],
		waterTemp: waterTemp[5],
		ID: 5,
	},
	{
		initial: 'J',
		max: maxTemp[6],
		min: minTemp[6],
		sunshineHours: sunshineHours[6],
		waterTemp: waterTemp[6],
		ID: 6,
	},
	{
		initial: 'J',
		max: maxTemp[7],
		min: minTemp[7],
		sunshineHours: sunshineHours[7],
		waterTemp: waterTemp[7],
		ID: 7,
	},
	{
		initial: 'A',
		max: maxTemp[8],
		min: minTemp[8],
		sunshineHours: sunshineHours[8],
		waterTemp: waterTemp[8],
		ID: 8,
	},
	{
		initial: 'S',
		max: maxTemp[9],
		min: minTemp[9],
		sunshineHours: sunshineHours[9],
		waterTemp: waterTemp[9],
		ID: 9,
	},
	{
		initial: 'O',
		max: maxTemp[10],
		min: minTemp[10],
		sunshineHours: sunshineHours[10],
		waterTemp: waterTemp[10],
		ID: 10,
	},
	{
		initial: 'N',
		max: maxTemp[11],
		min: minTemp[11],
		sunshineHours: sunshineHours[11],
		waterTemp: waterTemp[11],
		ID: 11,
	},
	{
		initial: 'D',
		max: maxTemp[12],
		min: minTemp[12],
		sunshineHours: sunshineHours[12],
		waterTemp: waterTemp[12],
		ID: 12,
	},
];

const currentMonth = ref(months[new Date(Date.now()).getMonth()]);

const climateLabel = computed((): string => {
	const baseString = 'Wetter';

	if (!props.climateRegion) {
		return baseString;
	}

	return `${baseString} (${props.climateRegion})`;
});

const updateMonth = (month: MonthClimate): MonthClimate => {
	currentMonth.value = month;
	return currentMonth.value;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(climateLabel.value), 1 /* TEXT */),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(BaseIcon, {
            class: "climate-chart__thermometer",
            name: "thermometer"
          }),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, "Max: " + _toDisplayString(currentMonth.value.max), 1 /* TEXT */),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "rpb_temp-max" }, null, -1 /* HOISTED */)),
              _cache[1] || (_cache[1] = _createTextVNode(" °C "))
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_7, "Min: " + _toDisplayString(currentMonth.value.min), 1 /* TEXT */),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "rpb_temp-min" }, null, -1 /* HOISTED */)),
              _cache[3] || (_cache[3] = _createTextVNode(" °C "))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "climate-chart__label-text" }, "Sonnenstunden: ", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_10, _toDisplayString(currentMonth.value.sunshineHours), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_11, [
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "climate-chart__label-text" }, "Wassertemperatur: ", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_12, _toDisplayString(currentMonth.value.waterTemp) + " °C", 1 /* TEXT */)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "climate-chart__climate-legend" }, [
          _createElementVNode("p", null, "30°"),
          _createElementVNode("p", null, "20°"),
          _createElementVNode("p", null, "10°")
        ], -1 /* HOISTED */)),
        _createElementVNode("div", {
          class: _normalizeClass(["teaser__scroll", ['teaser__scroll--left', 'teaser__scroll--right']])
        }, [
          _createElementVNode("ol", _hoisted_14, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(months, (month, index) => {
              return _createElementVNode("li", {
                key: index,
                index: index,
                style: _normalizeStyle(calculateHeight(month)),
                class: _normalizeClass(["climate-chart__slide", { 'climate-chart__slide--active': currentMonth.value.ID === month.ID }]),
                onMouseenter: () => updateMonth(month)
              }, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(month.initial), 1 /* TEXT */),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "climate-chart__bar" }, null, -1 /* HOISTED */))
              ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_15)
            }), 64 /* STABLE_FRAGMENT */))
          ])
        ])
      ])
    ])
  ]))
}
}

})